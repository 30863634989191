import { AppKeyChatKorean } from '@pixels/universal/model/apps/app-types';
import { ProjectMeta } from '@pixels/universal/model/apps/project-meta.model';
import { Phase } from '@pixels/universal/model/phase-model';

const FOLDER_NAME = 'chat-korean-client';
const PROD_WEB_PUSH_KEY = 'BLCZ1FRXNBQtQSnmIvXYaCwnd0MXnEsOZHLnjXPCIWTE8YY9HFVTvr4uvLV4lBwcHx6ZQl_Y5jPF-Tyf25_4aPo';
const DEV_WEB_PUSH_KEY = 'BMNJ7Is-ib7tDHPvVXqLEHbMmaM0PE-axg44PzT9UUa-bwLNr46jtrPQllNo4fY_FttiDdMaE7g3c1SGEWo79lc';
const PROD_AUTH_CALLBACK_URL = 'https://pixels-chat-korean.firebaseapp.com/__/auth/handler';
const DEV_AUTH_CALLBACK_URL = 'https://pixels-chat-korean-dev.firebaseapp.com/__/auth/handler';
const ProdFirebaseConfig = {
  apiKey: 'AIzaSyDI7NqvI398ceWCZpx_rHh20Wm5oAVn2UA',
  authDomain: 'pixels-chat-korean.firebaseapp.com',
  projectId: 'pixels-chat-korean',
  storageBucket: 'pixels-chat-korean.appspot.com',
  messagingSenderId: '145595004585',
  appId: '1:145595004585:web:3fed51668992cb61851bb8',
  measurementId: 'G-21N1QSBEYJ'
};
const DevFirebaseConfig = {
  apiKey: 'AIzaSyChGJ8vBQPtCeC-T6xZ8oe3IP4Zncqna04',
  authDomain: 'pixels-chat-korean-dev.firebaseapp.com',
  projectId: 'pixels-chat-korean-dev',
  storageBucket: 'pixels-chat-korean-dev.appspot.com',
  messagingSenderId: '716040711143',
  appId: '1:716040711143:web:91f86da54ffeb156cdab42',
  measurementId: 'G-H3PGGJB204'
};
const PROD_TALK_PLUS_APP_ID = '0ad8cafb-f74b-49b6-89d4-a85bac790adc';
const DEV_TALK_PLUS_APP_ID = '618a8b13-f9f9-460e-abea-aaf7728569e4';

export const ChatKoreanProjectMeta: ProjectMeta = {
  id: AppKeyChatKorean,
  mainPrimaryColor: '#0084FF',
  folderName: FOLDER_NAME,
  publicPath: `dist/apps/${FOLDER_NAME}/browser`,
  supportPhases: [Phase.prod, Phase.dev],
  port: 8111,
  apiName: 'chat-korean-api',
  apiPort: 5011,
  appId: 'io.pixelteam.chatkorean',
  appStoreUrl: 'https://apps.apple.com/app/id6499508025',
  appName: 'Chat Korean',
  appNameEn: 'Chat Korean',
  appSchemeName: 'ChatKorean',
  testTargetUrl: 'https://test-chatkorean.pixelteam.io',
  devTargetUrl: 'https://dev-chatkorean.pixelteam.io',
  prodApi: 'https://chatkorean-api.pixelteam.io',
  devApi: 'https://dev-chatkorean-api.pixelteam.io',
  firebaseId: 'pixels-chat-korean',
  iosAppId: '1:198703238152:ios:0e61264462275a444815bd',
  androidAppId: '1:198703238152:android:82ae8ea509379a464815bd',
  testHostname: 'test-chatkorean',
  clientSentryDsn: 'https://ddb7faa187cd802e3d2db6bcd1b6aead@o190729.ingest.us.sentry.io/4507164913893376',
  prod: {
    deployPath: '/api/v1/project/9070/stage/10519/scenario/11806/deploy',
    targets: ['prod-01'],
    port: 8200,
    androidAppId: '1:145595004585:android:c7773d3469a1c08c851bb8',
    iosAppId: '1:145595004585:ios:820af450b0822766851bb8',
    iosEncodingAppId: 'app-1-145595004585-ios-820af450b0822766851bb8',
    webAppId: '1:145595004585:web:3fed51668992cb61851bb8',
    kakaoAppKey: '68c12188d74ca26d8dd66fa85ac6e2ea',
    googleReversedId: 'com.googleusercontent.apps.145595004585-3spuo5fakea30bq8f028fali0mfgdtsn',
    webPushKey: PROD_WEB_PUSH_KEY,
    firebaseConfig: ProdFirebaseConfig,
    talkPlusAppId: PROD_TALK_PLUS_APP_ID,
    apiUrl: 'https://chatkorean-api.pixelteam.io'
  },
  dev: {
    deployPath: '/api/v1/project/9070/stage/10104/scenario/11271/deploy',
    targets: ['dev-01'],
    port: 8201,
    androidAppId: '1:716040711143:android:4e0ecc6d2338d947cdab42',
    iosAppId: '1:716040711143:ios:0248b186396de679cdab42',
    iosEncodingAppId: 'app-1-716040711143-ios-0248b186396de679cdab42',
    webAppId: '1:716040711143:web:91f86da54ffeb156cdab42',
    kakaoAppKey: 'ef9b1f727ba759044ba9dca4fdad6d29',
    googleReversedId: 'com.googleusercontent.apps.716040711143-1nvnhu017opnm6kveotrjclspsvovjpi',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://dev-chatkorean-api.pixelteam.io'
  },
  test: {
    androidAppId: '1:716040711143:android:aebacf98d42709a4cdab42',
    iosAppId: '1:716040711143:ios:58c00f9dbd29244acdab42',
    iosEncodingAppId: 'app-1-716040711143-ios-58c00f9dbd29244acdab42',
    kakaoAppKey: 'ef9b1f727ba759044ba9dca4fdad6d29',
    googleReversedId: 'com.googleusercontent.apps.716040711143-ikqq7g08vhjq5qrch4jt09ld6d8phje1',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://test-chatkorean-api.pixelteam.io'
  },
  android: {
    admobAppId: 'ca-app-pub-4000507444081320~4345330612',
    admobBannerListAppId: 'ca-app-pub-4000507444081320/1256132847',
    admobBannerChatRoomAppId: 'ca-app-pub-4000507444081320/2764655632',
    admobBannerVideoChatAppId: 'ca-app-pub-4000507444081320/5638519961',
  },
  ios: {
    admobAppId: 'ca-app-pub-4000507444081320~2328906906',
    admobBannerListAppId: 'ca-app-pub-4000507444081320/7613023171',
    admobBannerChatRoomAppId: 'ca-app-pub-4000507444081320/5038830720',
    admobBannerVideoChatAppId: 'ca-app-pub-4000507444081320/6317655745',
  },
  NSCameraUsageDescription: '카메라로 찍은 사진을 메시지에 첨부 또는 프로필 사진으로 등록할 수 있습니다.',
  NSPhotoLibraryAddUsageDescription: '회원님이 ChatKorean에 사진을 저장에 대한 액세스 권한을 사용합니다.',
  NSPhotoLibraryUsageDescription: '앨범에 있는 사진과 GIF를 메시지에 첨부 또는 프로필 사진으로 등록할 수 있습니다.',
  NSCameraUsageDescriptionEn: 'You can attach photos taken with your camera to messages or register them as your profile picture',
  NSPhotoLibraryAddUsageDescriptionEn: 'You use access to store photos in ChatKorean',
  NSPhotoLibraryUsageDescriptionEn: 'You can attach photos and GIFs from your albums to messages or as profile pictures',
  consoleLogo: `
 ██████╗██╗  ██╗ █████╗ ████████╗   ██╗  ██╗ ██████╗ ██████╗ ███████╗ █████╗ ███╗   ██╗
██╔════╝██║  ██║██╔══██╗╚══██╔══╝   ██║ ██╔╝██╔═══██╗██╔══██╗██╔════╝██╔══██╗████╗  ██║
██║     ███████║███████║   ██║█████╗█████╔╝ ██║   ██║██████╔╝█████╗  ███████║██╔██╗ ██║
██║     ██╔══██║██╔══██║   ██║╚════╝██╔═██╗ ██║   ██║██╔══██╗██╔══╝  ██╔══██║██║╚██╗██║
╚██████╗██║  ██║██║  ██║   ██║      ██║  ██╗╚██████╔╝██║  ██║███████╗██║  ██║██║ ╚████║
 ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝      ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═══╝`
};

