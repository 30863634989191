import { inject, Injectable, signal } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Platform } from '@ionic/angular/standalone';
import { ONE_ON_ONE_ADMIN_MENUS } from '@pixels/client/pages/admin/admin.model';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { AppKeyChatKorean } from '@pixels/universal/model/apps/app-types';
import { ChatKoreanProjectMeta } from '@pixels/universal/model/apps/project-meta/chat-korean-project-meta';

@Injectable({ providedIn: 'root' })
export class ChatKoreanAppInfo extends AppInfo {
  public readonly appType = AppKeyChatKorean;
  public readonly serviceName = _('@Chat_Korean@');
  public readonly appStoreUrl = signal(ChatKoreanProjectMeta.appStoreUrl ?? '');
  public readonly playStoreUrl = signal(`https://play.google.com/store/apps/details?id=${ChatKoreanProjectMeta.appId}`);

  public override readonly enabledBoardPostsDefaultNation: boolean = false;
  public override readonly enabledOpenChatsPostDefaultNation: boolean = false;
  public override readonly enabledWaitingRoomsDefaultNation: boolean = false;
  public override readonly admobBannerListAppId = (inject(Platform).is('ios') ? ChatKoreanProjectMeta.ios?.admobBannerListAppId : ChatKoreanProjectMeta.android?.admobBannerListAppId) ?? '';
  public override readonly loginTitle = _('@Chat_Korean@');
  public override readonly loginDescription = _('@새로운_외국인_친구를_만들어보아요@');
  public override readonly loginAppIconUrl = 'https://cdn.pixelteam.io/pixels/app-icons/chat-korean-app-icon.svg';
  public override readonly bannerPlaceHolder = _('@CHAT_Korean_외국인_친구_만들기_언어교환@');
  public override readonly adminMenus = ONE_ON_ONE_ADMIN_MENUS;
  public override readonly enabledWaitingRoomInitGenderFilter: boolean = false;
}
